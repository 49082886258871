<template>
    <div>
        <CompModal ref="comp_modal" title="周报" @on-scroll-bottom="onScrollBottom" width="450px">
            <div class="page">
                <div class="head">
                    <div class="source">广州市【社区随约服务网上驿站】</div>
                    <div class="title">工·作·周·报</div>
                </div>
                <div class="list-box">
                    <div class="item-box" v-for="(item, idx) in list" :key="idx" @click="onJumpDetail(item)">
                        <div class="info-box">
                            <p class="date">{{ item.itemPeriod }}</p>
                            <p class="read">阅 {{ item.viewNum }} 次</p>
                        </div>
                        <div class="operate">
                            <p class="name">查阅</p>
                            <div class="icon liefeng-icon liefeng-icon-fangxiang"></div>
                        </div>
                    </div>
                    <div class="empty" v-if="list.length === 0">暂无数据</div>
                </div>
            </div>
        </CompModal>

        <Audit ref="audit" source="published"></Audit>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Audit from "../audit.vue"

import RequestPage from "../../jointly/utils/page"

export default {
    components: {
        CompModal,
        Audit,
    },

    data() {
        return {
            list: [],
        }
    },

    created() {
        this.reqPage = new RequestPage("/gateway/api/symanage/pc/report/listReportDetailPage", {
            load: false,
            data: {
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                reportName: window.sessionStorage.getItem("dimensionId") == 2 ? "社区工作周报" : "街道工作周报",
                status: 7,
            },
            onChange: res => {
                console.log(res)
                this.list = res
            },
        })
    },

    methods: {
        async display() {
            // 初始化
            this.list = []
            // 获取数据
            this.reqPage.reset()
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        onJumpDetail(item) {
            this.$refs.comp_modal.close()
            this.$refs.audit.load(item.itemId, true)
        },

        /**
         * 滚动到底部
         */
        onScrollBottom() {
            this.reqPage.load()
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    .head {
        width: 100%;
        border-bottom: 1px solid red;
        padding: 25px 10px 15px 10px;
        box-sizing: border-box;
        line-height: 1;

        .source {
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 15px;
            text-align: center;
            color: red;
        }

        .title {
            font-size: 28px;
            margin-bottom: 15px;
            text-align: center;
            color: red;
        }

        .push {
            font-size: 14px;
            color: #666;
        }
    }

    .uptime {
        margin: 15px 15px;
        text-align: center;
        font-size: 28px;

        .strong {
            font-size: 20px;
            margin: 0 5px;
        }
    }

    .list-box {
        margin: 15px;

        .item-box {
            cursor: pointer;
            margin-bottom: 12px;
            padding: 15px 10px;
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid #d3d3d3;
            display: flex;
            align-items: center;

            .info-box {
                flex: 1;
                margin-right: 10px;

                .date {
                    font-size: 14px;
                    margin-bottom: 3px;
                }

                .read {
                    font-size: 12px;
                    color: #666;
                }
            }

            .operate {
                flex-shrink: 0;
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 5px;
                    font-size: 12px;
                    display: flex;
                    font-weight: bold;
                    color: #888;
                }
            }
        }

        .empty {
            margin: 25px 15px;
            color: #888;
            font-size: 13px;
            text-align: center;
        }
    }
}
</style>
